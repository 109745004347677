exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-coming-soon-index-tsx": () => import("./../../../src/pages/coming-soon/index.tsx" /* webpackChunkName: "component---src-pages-coming-soon-index-tsx" */),
  "component---src-pages-dashboard-tsx": () => import("./../../../src/pages/dashboard/[...].tsx" /* webpackChunkName: "component---src-pages-dashboard-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-mdx-fields-source-mdx-frontmatter-slug-tsx-content-file-path-src-data-products-example-index-mdx": () => import("./../../../src/pages/{mdx.fields__source}/{mdx.frontmatter__slug}.tsx?__contentFilePath=/opt/build/repo/src/data/products/example/index.mdx" /* webpackChunkName: "component---src-pages-mdx-fields-source-mdx-frontmatter-slug-tsx-content-file-path-src-data-products-example-index-mdx" */),
  "component---src-pages-mdx-fields-source-mdx-frontmatter-slug-tsx-content-file-path-src-data-products-second-example-index-mdx": () => import("./../../../src/pages/{mdx.fields__source}/{mdx.frontmatter__slug}.tsx?__contentFilePath=/opt/build/repo/src/data/products/second-example/index.mdx" /* webpackChunkName: "component---src-pages-mdx-fields-source-mdx-frontmatter-slug-tsx-content-file-path-src-data-products-second-example-index-mdx" */),
  "component---src-pages-mdx-fields-source-mdx-frontmatter-slug-tsx-content-file-path-src-data-promotions-2023-06-01-some-promo-index-mdx": () => import("./../../../src/pages/{mdx.fields__source}/{mdx.frontmatter__slug}.tsx?__contentFilePath=/opt/build/repo/src/data/promotions/2023-06-01-some-promo/index.mdx" /* webpackChunkName: "component---src-pages-mdx-fields-source-mdx-frontmatter-slug-tsx-content-file-path-src-data-promotions-2023-06-01-some-promo-index-mdx" */),
  "component---src-pages-mdx-fields-source-mdx-frontmatter-slug-tsx-content-file-path-src-data-promotions-2023-07-31-example-promotion-mdx": () => import("./../../../src/pages/{mdx.fields__source}/{mdx.frontmatter__slug}.tsx?__contentFilePath=/opt/build/repo/src/data/promotions/2023-07-31-example-promotion.mdx" /* webpackChunkName: "component---src-pages-mdx-fields-source-mdx-frontmatter-slug-tsx-content-file-path-src-data-promotions-2023-07-31-example-promotion-mdx" */)
}


import React from 'react';
import { ReactCookieProps, useCookies } from 'react-cookie';
import {
  AgeGateCookieEnum,
  TableauTokenCookieEnum,
  ThemeModeEnum,
} from '../../utils/enums';

export interface RootState {
  showSignIn: boolean;
  account: any;
  searchValue: string;
  sidebarOpen: boolean;
  // Differentiation between `selectedTheme` and `effectiveTheme` so we can store `auto` in localStorage and
  // set dark/light on each load.  However, the "effectiveTheme" the one which determines the _actual_ colors used.
  theme: {
    selectedTheme: ThemeModeEnum;
    effectiveTheme: Exclude<ThemeModeEnum, 'auto'>;
  };
  dateBICognos: string;
  updateTheme: (darkMode: ThemeModeEnum) => void;
  acceptSignIn: (
    credentials: CredentialsProps,
    jwt: string,
    account: any
  ) => void;
  updateSearchValue: (value: string) => void;
  updateSidebarOpen: (value: string) => void;
  updateAccount: (value: string) => void;
  updateDateBICognos: (value: string) => void;
}

export interface SiteProps {
  id: string;
  contentUrl: string;
}
export interface UserProps {
  id: string;
}
export interface CredentialsProps {
  site: SiteProps;
  token: string;
  user: UserProps;
}

export const RootContext = React.createContext<RootState>({
  showSignIn: true,
  account: null,
  searchValue: '',
  sidebarOpen: true,
  theme: {
    selectedTheme: ThemeModeEnum.auto,
    effectiveTheme: ThemeModeEnum.light,
  },
  dateBICognos: '',
  updateTheme: (_) => {},
  acceptSignIn: (_) => {},
  updateSearchValue: (_) => {},
  updateSidebarOpen: (_) => {},
  updateAccount: (_) => {},
  updateDateBICognos: (_) => {},
});
export default RootContext;

/**
 * Initializes the Google tag by checking for `utm_X` URL parameters.  If any are found,
 * they are set globally in the tag.
 *
 * @param status {'granted' | 'denied' | null} If `null`, will not fire `setTag`.  Otherwise, passes
 * value to `setTag` to actively set user's consent value for cookie-related storage.
 *
 * @returns {void}
 */
function initGTag(status: 'granted' | 'denied' | null): void {
  if (!!status) setTag(status);
  if ('gtag' in window && typeof window.gtag === 'function') {
    const params = new URLSearchParams(window.location.search);
    const campaignValues: Record<string, string> = {};

    // Ref: https://support.google.com/analytics/answer/10917952?hl=en
    const campaign_id = params.get('utm_id');
    const campaign_name = params.get('utm_campaign');
    const campaign_medium = params.get('utm_medium');
    const campaign_source = params.get('utm_source');

    if (!!campaign_id) campaignValues[campaign_id] = campaign_id;
    if (!!campaign_name) campaignValues[campaign_name] = campaign_name;
    if (!!campaign_medium && !!campaign_source) {
      // Ref: https://support.google.com/analytics/answer/11259997?hl=en
      // "medium" and "source" are required.  Without these, nothing can be set.
      // There's a URL builder here: https://ga-dev-tools.google/ga4/campaign-url-builder/
      campaignValues[campaign_medium] = campaign_medium;
      campaignValues[campaign_source] = campaign_source;
      window.gtag('set', campaignValues);
    }
  }
}

/**
 * D.R.Y. wrapper to return the "actual" theme in use by the client, given the input `selectedTheme`.
 * This will output _only_ `light/dark`, so `auto` does not need to be handled in each and every case.
 * This is used entirely within this context, and for the purpose of setting `theme.effectiveTheme` state.
 * */
function getTheme(
  selectedTheme: string | null
): RootState['theme']['effectiveTheme'] {
  if (
    selectedTheme === ThemeModeEnum.dark ||
    selectedTheme == ThemeModeEnum.light
  )
    return selectedTheme;
  return window.matchMedia('(prefers-color-scheme: dark)').matches
    ? ThemeModeEnum.dark
    : ThemeModeEnum.light;
}

/**
 * Wrapper for the Google Tag interface to grant/deny permission to use storage for Google Analytics tracking.
 *
 * @param status {('granted' | 'denied')} - Passed to `gtag`'s consent setting for all storage options.
 * @returns {void}
 * */
function setTag(status: 'granted' | 'denied'): void {
  // Check that `gtag` exists on the window.  If so, update the consent status with
  // either `granted` or `denied` as appropriate.
  if ('gtag' in window && typeof window.gtag === 'function') {
    window.gtag('consent', 'update', {
      ad_storage: status,
      analytics_storage: status,
    });
  }
}

export const RootProvider: React.FC<React.PropsWithChildren> = (props) => {
  const [cookies, setCookies] = useCookies([TableauTokenCookieEnum.CookieName]);

  const [account, setAccount] = React.useState();
  const msal_keys = sessionStorage.getItem('msal.account.keys');

  const [showSignIn, setShowSignIn] = React.useState(
    !cookies[TableauTokenCookieEnum.CookieName] || !msal_keys || !account
  );

  const [searchValue, setSearchValue] = React.useState('');

  const [sidebarOpen, setSidebarOpen] = React.useState('');

  const [dateBICognos, setDateBICognos] = React.useState('');

  const [theme, setTheme] = React.useState((): RootState['theme'] => {
    // Generate in a callback to ensure `localStorage` is available (i.e. this is running on the client).
    // Get the value from localStorage and ensure it's of the ThemeModeEnum type (and not just _any_ string), or default to `auto`.
    // Transform that `selectedTheme` into its related `effectiveTheme` and store.
    const localDM = localStorage.getItem('data-bs-theme');
    const selectedTheme = [
      ThemeModeEnum.auto,
      ThemeModeEnum.dark,
      ThemeModeEnum.light,
    ].includes(localDM as ThemeModeEnum)
      ? (localDM as ThemeModeEnum)
      : ThemeModeEnum.auto;

    return {
      selectedTheme,
      effectiveTheme: getTheme(selectedTheme),
    };
  });

  /**
   * Callback for the `AgeGateComponent` which handles setting the appropriate cookie values.  Gets passed through
   * the RootContext.Consumer into the <AgeGateComponent />.
   *
   * @param rememberMe {boolean} - If `false`, the age-gate cookie will be created as a Session cookie (i.e. expires
   * on browser close).  If `true`, then the cookie will have a `maxAge` defined by the `AgeGateCookieEnum.CookieDuration`
   * value.
   *
   * @param allowAll {boolean} - If `true`, will consider cookie consent as "allow all" and thus will grant Google
   * Tag storage access.  If `false`, the user consents to required cookies _only_, and thus will set GTag storage
   * access to `denied.`
   * */
  function acceptSignIn(
    credentials: CredentialsProps,
    jwt: string,
    account: any
  ): void {
    // Init without `expires` or `maxAge`, so the default cookies are session-based.
    // If the user has selected `rememberMe`, then set a `maxAge`.
    const baseOptions: ReactCookieProps['defaultSetOptions'] = {
      domain: window.location.hostname
        .split('.')
        .filter((s) => s !== 'www')
        .join('.'),
      path: '/',
      sameSite: 'lax',
    };

    setAccount(account);

    setCookies(
      TableauTokenCookieEnum.CookieName,
      credentials.token,
      baseOptions
    );

    typeof window !== 'undefined' &&
      sessionStorage.setItem('user', JSON.stringify(credentials.user));
    typeof window !== 'undefined' &&
      sessionStorage.setItem('site', JSON.stringify(credentials.site));
    typeof window !== 'undefined' && sessionStorage.setItem('jwt', jwt);
    typeof window !== 'undefined' &&
      sessionStorage.setItem('account', JSON.stringify(account));
    // Update the gTag based on the response.
    //setTag(allowAll ? 'granted' : 'denied');

    setShowSignIn(false);
  }

  function updateTheme(newTheme: ThemeModeEnum) {
    setTheme({
      selectedTheme: newTheme,
      effectiveTheme: getTheme(newTheme),
    });
  }

  function updateSearchValue(value: string) {
    setSearchValue(value);
  }
  function updateSidebarOpen(value: string) {
    setSidebarOpen(value);
  }
  function updateAccount(value: string) {
    setAccount(value);
  }

  function updateDateBICognos(value: string) {
    setDateBICognos(value);
  }

  const providerState: RootState = {
    showSignIn,
    account,
    searchValue,
    theme,
    dateBICognos,
    updateTheme: updateTheme.bind(this),
    acceptSignIn: acceptSignIn.bind(this),
    updateSearchValue: updateSearchValue.bind(this),
    updateSidebarOpen: updateSidebarOpen.bind(this),
    updateAccount: updateAccount.bind(this),
    updateDateBICognos: updateDateBICognos.bind(this),
  };

  // Run this in `useEffect` to ensure it's on the client site (and thus `window` exists).
  React.useEffect(() => {
    const initValue = [
      AgeGateCookieEnum.ConsentAllValue,
      AgeGateCookieEnum.ConsentReqValue,
    ].includes(cookies.ageCheck)
      ? cookies.ageCheck === AgeGateCookieEnum.ConsentAllValue
        ? 'granted'
        : 'denied'
      : null; // Prevents setTag from being fired if the AgeGateCookie isn't set yet.

    initGTag(initValue);
  }, []);

  // Watcher for changes in the `theme` state.
  React.useEffect(() => {
    // Store the updated `selectedTheme` in localStorage.
    localStorage.setItem('data-bs-theme', theme.selectedTheme);
    // and set the `effectiveTheme` in the document; Bootstrap's CSS responds to automatically to this data attr
    document.documentElement.setAttribute(
      'data-bs-theme',
      theme.effectiveTheme
    );
    // Splitting `selected` and `effective` allows us to include `auto` in localStorage, but directly apply only dark/light to the _actual_ page.
  }, [theme]);

  return (
    <RootContext.Provider value={providerState}>
      {props.children}
    </RootContext.Provider>
  );
};

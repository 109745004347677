export const enum AgeGateCookieEnum {
  CookieName = 'ageCheck',
  ConsentAllValue = 'a',
  ConsentReqValue = 'r',
  CookieDuration = 3600 * 24 * 30, // 30 days, in seconds
}

export const enum TableauTokenCookieEnum {
  CookieName = 'tokenTableau',
  CookieDuration = 6000, // 10 minutes. It's the max allowed by Tableau API
}

export const enum ThemeModeEnum {
  auto = 'auto',
  dark = 'dark',
  light = 'light',
}
